import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.prod";

const AUTH_API_BASE_URL = `${environment.baseUrl}/api/v1/auth/`;
///api/v1/access-management/auth/login
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly headers: HttpHeaders = new HttpHeaders().set(
    "Content-Type",
    "application/json"
  );

  constructor(private http: HttpClient) {}
///api/v1/auth/login
  login(credentials: any): Observable<any> {
    return this.http.post(AUTH_API_BASE_URL + "login", credentials, {
      observe: "response",
      headers: this.headers,
      withCredentials: true,
    });
  }

   verifyOTP(body: any): Observable<any> {
    return this.http.post(AUTH_API_BASE_URL + "verify-token", body);
  }
   forgotPassword(body: any): Observable<any> {
    return this.http.post(AUTH_API_BASE_URL + "forgot-password", body);
  }
  resetPassword(body: any): Observable<any> {
    return this.http.post(AUTH_API_BASE_URL + "reset-password", body);
  }

  logout(refreshToken: string): Observable<any> {
    const params: HttpParams = new HttpParams().set("refreshToken", refreshToken);
    return this.http.put(AUTH_API_BASE_URL + "logout", {}, { params });
  }

  postProfileImage(imageBody: any): Observable<any> {
    return this.http.post(AUTH_API_BASE_URL + "users/image/maintain", imageBody);
  }

  getUserProfileImage(params: any): Observable<any> {
    return this.http.get(AUTH_API_BASE_URL + "users/image/find-by-emppf", { params });
  }
}
