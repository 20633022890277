export const environment = {
  production: true,

  // Test server
  //baseUrl: "https://213.199.43.99:8432",

  baseUrl: "https://api.digitalmmita.com",

  //baseUrl: "https://dev.digitalmmita.com",

  // baseUrl: "http://localhost:8432",

};



