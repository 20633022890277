import { RouteInfo } from "./sidebar.metadata";

export const AdminModule: RouteInfo[] = [
  //************************************************************************************************************************* */
  //ADMIN MODULE
  //************************************************************************************************************************* */
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["All"],
    submenu: [],
  },

  {
    path: "/admin/dashboard",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "monitor",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["All"],
    submenu: [],
  },

  {
    path: "",
    title: "Config-Modules",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["All"],
    submenu: [],
  },

  {
    path: "/admin/configurations/all-areas",
    title: "Areas",
    moduleName: "Areas",
    iconType: "feather",
    icon: "map-pin",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: [
      "CREATE_AREA",
      "UPDATE_AREA",
      "UPDATE_AREA_STATUS",
      "VIEW_AREA_DETAILS",
      "VIEW_AREAS",
    ],
    submenu: [],
  },

  {
    path: "/admin/configurations/all-dcu",
    title: "DCUs",
    moduleName: "DCUs",
    iconType: "feather",
    icon: "server",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["CREATE_DCU", "UPDATE_DCU", "VIEW_DCUS", "DELETE_DCU"],
    submenu: [],
  },

  {
    path: "/admin/configurations/all-meters",
    title: "Meters",
    moduleName: "Meters",
    iconType: "feather",
    icon: "activity",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: [
      "CREATE_METER",
      "UPDATE_METER",
      "VIEW_METERS",
      "USER_METERS",
      "DELETE_METER",
    ],
    submenu: [],
  },

  {
    path: "/admin/configurations/mpesa-credentials",
    title: "Mpesa-credentials",
    moduleName: "mpesa-credentials",
    iconType: "feather",
    icon: "shield",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: [ "CREATE_METER",],
    submenu: [],
  },

  {
    path: "/admin/configurations/all-charges",
    title: "Charges",
    moduleName: "Meters",
    iconType: "feather",
    icon: "dollar-sign",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["VIEW_CHARGES", "UPDATE_CHARGES"],
    submenu: [],
  },

  {
    path: "/admin/configurations/bulk-uploads",
    title: "Bulk Imports",
    moduleName: "bulk-imports",
    iconType: "feather",
    icon: "upload",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: [
      "USER_BULK_UPLOAD",
      "AREA_BULK_UPLOAD",
      "DCU_BULK_UPLOAD",
      "METER_BULK_UPLOAD",
      "DOWNLOAD_USER_TEMPLATE",
      "DOWNLOAD_AREA_TEMPLATE",
      "DOWNLOAD_DCU_TEMPLATE",
      "DOWNLOAD_METER_TEMPLATE",
    ],
    submenu: [],
  },


  {
    path: "",
    title: "Main-Modules",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["All"],
    submenu: [],
  },

  {
    path: "/admin/transactions/all-payments",
    title: "Payments",
    moduleName: "transactions",
    iconType: "feather",
    icon: "credit-card",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["VIEW_PAYMENTS", "PAYMENTS_BY_SHORT_CODE"],
    submenu: [],
  },

  {
    path: "/admin/purchases/all-purchases",
    title: "Purchases",
    moduleName: "purchases",
    iconType: "feather",
    icon: "shopping-cart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["VIEW_PURCHASES", "PURCHASES_BY_SHORT_CODE"],
    submenu: [],
  },

  {
    path: "/admin/purchases/all-settlements",
    title: "Settlements",
    moduleName: "settlements",
    iconType: "feather",
    icon: "check-square",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["VIEW_SETTLEMENTS", "SETTLEMENTS_BY_SHORT_CODE"],
    submenu: [],
  },

  {
    path: "/admin/purchases/all-settlement-charges",
    title: "Accrued Charges",
    moduleName: "settlements-charges",
    iconType: "feather",
    icon: "file-text",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["VIEW_CHARGE_DETAILS"],
    submenu: [],
  },

  {
    path: "/admin/messages/all-messages",
    title: "Messages",
    moduleName: "messages",
    iconType: "feather",
    icon: "mail",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: ["All"],
    submenu: [],
  },

  {
    path: "/admin/users/all-roles",
    title: "Roles Management",
    moduleName: "roles-management",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: [
      "VIEW_ACCESS_RIGHTS",
      "CREATE_ROLE",
      "UPDATE_ROLE",
      "UPDATE_ROLE_STATUS",
      "VIEW_ROLE_DETAILS",
      "VIEW_ROLES",
    ],
    submenu: [],
  },

  {
    path: "/admin/users/all-users",
    title: "User Management",
    moduleName: "users-management",
    iconType: "feather",
    icon: "user-check",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    privilege: [
      "CREATE_USER",
      "UPDATE_USER",
      "DELETE_USER",
      "UPDATE_USER_ROLE",
      "VIEW_USERS",
      "REMOVE_USER",
    ],
    submenu: [],
  },
];

// {
//   path: "",
//   title: "Configurations",
//   moduleName: "configurations",
//   iconType: "feather",
//   icon: "grid",
//   class: "menu-toggle",
//   groupTitle: false,
//   badge: "",
//   badgeClass: "",
//   role: ["All"],
//   privilege: ["All"],
//   submenu: [
//     {
//       path: "/admin/configurations/all-areas",
//       title: "Areas",
//       moduleName: "configurations",
//       iconType: "",
//       icon: "",
//       class: "ml-menu",
//       groupTitle: false,
//       badge: "",
//       badgeClass: "",
//       role: ["All"],
//       privilege: ["All"],
//       submenu: [],
//     },
//     {
//       path: "/admin/configurations/all-dcu",
//       title: "DCUs",
//       moduleName: "configurations",
//       iconType: "",
//       icon: "",
//       class: "ml-menu",
//       groupTitle: false,
//       badge: "",
//       badgeClass: "",
//       role: ["All"],
//       privilege: ["All"],
//       submenu: [],
//     },
//     {
//       path: "/admin/configurations/all-meters",
//       title: "Meters",
//       moduleName: "meters",
//       iconType: "",
//       icon: "",
//       class: "ml-menu",
//       groupTitle: false,
//       badge: "",
//       badgeClass: "",
//       role: ["All"],
//       privilege: ["All"],
//       submenu: [],
//     },
//   ],
// },
